const adminRoutes = [
  {
    path: '/admin',
    component: () => import('@/view/layout/Layout'),
    children: [
      {
        path: '/',
        redirect: {
          name: 'chat',
        },
      },
      {
        name: 'profile',
        path: 'profile',
        component: () => import('@/view/pages/Profile'),
        meta: {
          title: 'Admin | Client Profile',
          requiresAuth: true,
        },
      },
      {
        name: 'timeline',
        path: 'timeline',
        component: () => import('@/view/pages/Timeline'),
        meta: {
          title: 'Admin | Timeline',
          requiresAuth: true,
        },
      },
      {
        name: 'reyestr',
        path: 'reyestr',
        component: () => import('@/view/pages/Price'),
        meta: {
          title: 'Admin | Reyestr',
          requiresAuth: true,
        },
      },
      {
        name: 'price list',
        path: 'price-list',
        component: () => import('@/view/pages/PriceList'),
        meta: {
          title: 'Admin | Price List',
          requiresAuth: true,
        },
      },
      {
        name: 'product item',
        path: 'product-account/:id',
        component: () => import('@/view/pages/ProductAccount'),
        meta: {
          title: 'Admin | Product item',
          requiresAuth: true,
        },
      },
      {
        name: 'price list item',
        path: 'product-account-price-list/:id',
        component: () => import('@/view/pages/ProductAccountPriceList'),
        meta: {
          title: 'Admin | Price list item',
          requiresAuth: true,
        },
      },
      {
        name: 'pre-order',
        path: 'pre-order',
        component: () => import('@/view/pages/PreOrder'),
        meta: {
          title: 'Admin | Pre-order',
          requiresAuth: true,
        },
      },
      {
        name: 'mailbox',
        path: 'mailbox',
        component: () => import('@/view/pages/Mailbox'),
        meta: {
          title: 'Admin | Mail Box',
          requiresAuth: true,
        },
      },
      {
        name: 'compose',
        path: 'compose',
        component: () => import('@/view/pages/Compose'),
        meta: {
          title: 'Admin | Compose Message',
          requiresAuth: true,
        },
      },
      {
        name: 'read mail',
        path: 'read-mail',
        component: () => import('@/view/pages/ReadMail'),
        meta: {
          title: 'Admin | Read Mail',
          requiresAuth: true,
        },
      },
      {
        name: 'invoice',
        path: 'invoice',
        component: () => import('@/view/pages/Invoice'),
        meta: {
          title: 'Admin | Invoice',
          requiresAuth: true,
        },
      },
      {
        name: 'invoice bill',
        path: 'invoice-bill/:id',
        component: () => import('@/view/pages/InvoiceBill'),
        meta: {
          title: 'Admin | Invoice',
          requiresAuth: true,
        },
      },
      {
        name: 'invoice form',
        path: 'invoice-form',
        component: () => import('@/view/pages/InvoiceForm'),
        meta: {
          title: 'Admin | Invoice',
          requiresAuth: true,
        },
      },
      {
        name: 'order account',
        path: 'order-account',
        component: () => import('@/view/pages/OrderAccount'),
        meta: {
          title: 'Admin | Order Account',
          requiresAuth: true,
        },
      },
      {
        name: 'product description',
        path: 'order-account/product-description',
        component: () => import('@/view/pages/ProductDescription'),
        meta: {
          title: 'Admin | Product Description',
          requiresAuth: true,
        },
      },
      {
        path: 'order',
        component: () => import('@/view/pages/adminBasket/AdminOrder.vue'),
        // redirect: {
        name: 'admin registration',
        // },
        children: [
          {
            path: 'registration',
            name: 'admin order registration',
            component: () =>
              import('@/view/pages/adminBasket/AdminOrderRegistration.vue'),
            meta: {
              title: 'Admin | Registration',
              requiresAuth: true,
            },
          },
          {
            path: 'order-complete',
            name: 'admin order complete',
            component: () =>
              import('@/view/pages/adminBasket/AdminOrderComplete.vue'),
            meta: {
              title: 'Admin | Order complete',
              requiresAuth: true,
            },
          },
        ],
      },
      {
        name: 'order history',
        path: 'order-history',
        component: () => import('@/view/pages/OrderHistory'),
        meta: {
          title: 'Admin | Order History',
          requiresAuth: true,
        },
      },
      {
        name: 'order history retail',
        path: 'order-history-retail',
        component: () => import('@/view/pages/OrderHistoryRetail'),
        meta: {
          title: 'Admin | Order History Retail',
          requiresAuth: true,
        },
      },
      {
        name: 'order details',
        path: 'order-details',
        component: () => import('@/view/pages/OrderDetails'),
        meta: {
          title: 'Admin | Order Details',
          requiresAuth: true,
        },
      },
      {
        name: 'readonly product description',
        path: 'order-history/product-description/:id',
        component: () => import('@/view/pages/OrderAccount.vue'),
        // component: () => import('@/view/content/OrderDetails.vue'),
        meta: {
          title: 'Admin | Product Description',
          requiresAuth: true,
        },
      },
      {
        name: 'retail order description',
        path: 'order-history-retail/product-description-retail/:id',
        component: () => import('@/view/pages/OrderAccountRetail.vue'),
        // component: () => import('@/view/content/OrderDetails.vue'),
        meta: {
          title: 'Admin | Product Description',
          requiresAuth: true,
        },
      },
      {
        path: 'chats',
        component: () => import('@/view/pages/Chat.vue'),
        children: [
          {
            path: '/',
            name: 'chats',
            components: {
              chatsList: () => import('@/view/pages/ChatList.vue'),
            },
            meta: {
              title: 'Admin | Chat',
              requiresAuth: true,
              hideFooter: true,
            },
          },
          {
            path: ':id',
            name: 'chat dialog',
            components: {
              chatsList: () => import('@/view/pages/ChatList.vue'),
              chatDialog: () => import('@/view/pages/ChatDialog.vue'),
              chatDialogWholesale: () =>
                import('@/view/pages/ChatDialogWholesale.vue'),
            },
            meta: {
              title: 'Admin | Chat',
              requiresAuth: true,
              hideFooter: true,
            },
          },
        ],
      },
      {
        path: 'chat',
        component: () => import('@/view/pages/Chat.vue'),
        children: [
          {
            path: '/',
            name: 'chat',
            components: {
              // chatDialog: () => import('@/view/pages/ChatDialog.vue'),
              chatsList: () => import('@/view/pages/ChatList.vue'),
            },
            meta: {
              title: 'Admin | Chat',
              requiresAuth: true,
              hideFooter: true,
            },
          },
        ],
      },
    ],
  },
  {
    name: 'login',
    path: '/admin/login',
    component: () => import('@/view/pages/auth/Login'),
    meta: {
      title: 'Admin | Log in',
    },
  },
  {
    name: 'register',
    path: '/admin/register',
    component: () => import('@/view/pages/auth/Register'),
    meta: {
      title: 'Admin | Registration Page',
    },
  },
  {
    name: 'recover password',
    path: '/admin/recover-password',
    component: () => import('@/view/pages/auth/RecoverPassword'),
    meta: {
      title: 'Admin | Recover Password',
    },
  },
  {
    name: 'forgot password',
    path: '/admin/forgot-password',
    component: () => import('@/view/pages/auth/ForgotPassword'),
    meta: {
      title: 'Admin | Forgot Password',
    },
  },
]

export default adminRoutes
